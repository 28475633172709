<template>
  <section class="section tile is-ancestor my-6 pt-6">
    <div class="tile is-parent is-vertical">
      <div class="container tile is-vertical">
        <div class="container  mt-6">
          <img
          width="100"
          :src="require('@/assets/imagotipo.png')"
          alt="FecMX"
          class="center"
        />
        </div>

        <h1 class="title has-text-centered has-text-secondary">APPLY NOW</h1>
        <h2 class="title has-text-centered has-text-primary pt-3">Junior Controls Engineer</h2>
        <h3 class="subtitle has-text-centered mt-4">Fastening Electric Controls USA LLC</h3>

        <div class="content mt-5">
          <p>
            The Junior Controls Engineer will participate in system testing and debugging, support the installation and
            commissioning of automation systems, and conduct system validations to ensure functionality and compliance
            with engineering standards.
            The role involves creating and maintaining technical documentation, including electrical schematics and
            wiring diagrams, while ensuring adherence to industry regulations and safety protocols.
          </p>
        </div>

        <div class="content mt-6 tile is-vertical">
          <h3 class="has-text-weight-semibold is-size-4">Duties:</h3>

          <h4 class="has-text-weight-semibold is-size-5 mt-4">Design & Development</h4>
          <ul>
            <li>Assist in designing and programming PLC and HMI systems.</li>
            <li>Develop control logic using ladder logic, function block, or structured text code.</li>
            <li>Support sensor, actuator, and control system integration.</li>
          </ul>

          <h4 class="has-text-weight-semibold is-size-5 mt-4">Testing & Implementation</h4>
          <ul>
            <li>Help with testing, debugging, and troubleshooting control systems.</li>
            <li>Support automation system installation and commissioning.</li>
            <li>Conduct system validations to meet design requirements.</li>
          </ul>

          <h4 class="has-text-weight-semibold is-size-5 mt-4">Maintenance & Troubleshooting</h4>
          <ul>
            <li>Diagnose and resolve automation equipment issues.</li>
            <li>Perform routine maintenance and software updates.</li>
            <li>Collaborate with engineers and technicians to improve performance.</li>
          </ul>

          <h4 class="has-text-weight-semibold is-size-5 mt-4">Documentation & Compliance</h4>
          <ul>
            <li>Create and maintain schematics, wiring diagrams, and control docs.</li>
            <li>Ensure compliance with safety and industry standards.</li>
            <li>Prepare technical reports and manuals.</li>
          </ul>

          <h4 class="has-text-weight-semibold is-size-5 mt-4">Collaboration & Continuous Improvement</h4>
          <ul>
            <li>Work with engineers and production to improve efficiency.</li>
            <li>Assist R&D with new automation technologies.</li>
            <li>Stay current with industrial control system advancements.</li>
          </ul>
        </div>

        <div class="content mt-6">
          <p><strong>Location:</strong> 5805 CALLAGHAN RD #116 SAN ANTONIO, TEXAS 782289</p>
          <p><strong>Send your cv to:</strong> <a href="mailto:	facturas@fecmx.co">facturas@fecmx.co</a></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "JuniorControlsEngineerView"
};
</script>

<style scoped>
ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}
</style>
